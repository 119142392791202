<template>
  <div class="ticket-detail-container">
    <Breadcrumb>
      <BreadcrumbItem to="/ticket">{{ $t('gong-dan') }}</BreadcrumbItem>
      <BreadcrumbItem>{{ $t('gong-dan-xiang-qing') }}</BreadcrumbItem>
    </Breadcrumb>
    <Card class="ticket-detail-status">
      <div slot="title">
        <p class="ticket-title-p" style="display: flex;align-items: center;">
          <span class="ticket-title">{{ ticketDetail.ticketTitle }}</span>
          <span class="ticket-status-total">{{ TICKET_STATUS[ticketDetail.ticketStatus] }}</span>
        </p>
        <div class="ticket-detail-summary">
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">{{ $t('shen-qing-ren') }}:</span>
            <span>{{ ticketDetail.userName }}</span>
          </div>
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">{{ $t('huan-jing') }}:</span>
            <span>{{ ticketDetail.dsEnvName }}</span>
          </div>
          <div class="ticket-detail-item">
            <span class="ticket-detail-item-title">{{ $t('db-shi-li-ku-ming') }}:</span>
            <cc-data-source-icon color="#4DBAEE" :size="18" :type="ticketDetail.dataSourceType"
                                 :instanceType="ticketDetail.deployEnvType"></cc-data-source-icon>
            <span>{{ ticketDetail.targetInfo }}</span>
          </div>
          <div class="ticket-detail-item" v-if="ticketDetail.approTemplateName">
            <span class="ticket-detail-item-title">{{ $t('shen-pi-liu-mo-ban') }}:</span>
            <span>{{ ticketDetail.approTemplateName }}</span>
          </div>
        </div>
        <div class="ticket-detail-operators">
          <Button class="warning-btn" v-if="ticketDetail.canApproval" type="primary" @click="handleShowApprovalModal">
            {{ $t('shen-pi') }}
          </Button>
          <Button type="primary"
                    v-if="ticketDetail.canExecute"
                    @click="handleShowManualExecuteModal('CONFIRM_AND_MANUAL_EXEC')">{{ $t('zhi-hang') }}
          </Button>
          <Button v-if="ticketDetail.canClose" @click="handleShowCloseTicketModal">{{$t('guan-bi-gong-dan') }}
          </Button>
          <Button @click="getTicketDetail('refresh')" class="refresh-btn" :loading="loading">
            {{ $t('shua-xin') }}
          </Button>
        </div>
      </div>
      <div class="ticket-detail-wrapper" v-if="ticketDetail.ticketProcessVOList">
        <div class="step-item">
          <div class="step-item-item" style="width: 100px">
            <div class="status" style="border: 1px solid #52C41A;">
              <Icon type="ios-checkmark-circle" size="20" style="margin-right: 3px;" color="#52C41A"/>
              <div class="content">{{ $t('yi-ti-jiao') }}</div>
            </div>
            <div>{{ $t('chuang-jian') }}</div>
          </div>
          <div class="step-item-item">
            <div class="step-detail-label">{{ $t('shen-qing-ren') }}:</div>
            <Tooltip transfer>
              <div class="step-detail-value ellipsis">
                {{ ticketDetail.userName }}
              </div>
              <div slot="content">
                  {{ticketDetail.userName}}
              </div>
            </Tooltip>
          </div>
          <div class="step-item-item ">
            <div class="step-detail-label">{{ $t('shi-jian') }}:</div>
            <div class="step-detail-value">{{ ticketDetail.gmtCreate }}</div>
          </div>
          <div class="step-item-item">
            <div class="step-detail-label" style="min-width: 40px;">{{ $t('miao-shu') }}:</div>
            <Tooltip transfer>
              <div class="step-detail-value ellipsis">
                {{ ticketDetail.description }}
              </div>
              <div slot="content">
                {{ticketDetail.description}}
              </div>
            </Tooltip>
          </div>
        </div>
        <div :class="`step-item ${currentStep === index ? 'current-step' : ''}`"
             v-for="(process, index) in ticketDetail.ticketProcessVOList"
             :key="process.ticketProcessId">
          <div class="step-item-item">
            <div class="line" :style="`background: ${process.color}`"></div>
            <div class="status" :style="`border: 1px solid ${process.color};`">
              <Icon :type="process.icon" size="20" style="margin-right: 3px;z-index: 9"
                    :color="process.color"/>
              <div class="content">{{ process.label }}</div>
            </div>
            <div>{{ process.ticketStageTitle }}</div>
          </div>
          <div class="step-item-item">
            <div class="step-detail-label">{{ $t('chu-li-ren') }}:</div>
            <Tooltip transfer>
              <div class="step-detail-value ellipsis">
                {{ process.execUserName }}
              </div>
              <div slot="content">
                <div v-for="userName in process.execUserNameList" :key="userName">
                  {{userName}}
                </div>
              </div>
            </Tooltip>
          </div>
          <div class="step-item-item">
            <div class="step-detail-label">{{ $t('shi-jian') }}:</div>
            <div class="step-detail-value">{{ process.finishTime }}</div>
          </div>
          <div class="step-item-item">
            <div class="step-detail-label" style="min-width: 40px;">{{ $t('bei-zhu') }}:</div>
            <Tooltip transfer>
              <div class="step-detail-value ellipsis">
                {{ process.execMsg }}
              </div>
              <div slot="content">
                {{process.execMsg}}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </Card>
    <Card class="ticket-content">
      <div slot="title" style="display: flex;align-items: center">
        <div>{{ $t('gong-dan-nei-rong') }}</div>
        <Button type="link" @click="handleShowRollbackSqlModal" v-if="ticketDetail.rollBackSql" style="margin-left: 10px;">
          {{ $t('cha-kan-hui-gun-sql') }}
        </Button>
      </div>
      <read-only-editor :text="ticketDetail.rawSql" key="raw" :max-height="500"/>
    </Card>
    <Modal v-model="showApprovalModal" :title="$t('shen-pi')"
             :closable="false">
      <Form>
        <FormItem :label="$t('yi-jian')">
          <Select v-model="approvalData.rejected">
            <Option value="false">{{ $t('tong-yi') }}</Option>
            <Option value="true">{{ $t('ju-jue') }}</Option>
          </Select>
        </FormItem>
        <FormItem :label="$t('li-you')">
          <Input type="textarea" v-model="approvalData.comment"></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleApproval" type="primary">{{ $t('ti-jiao') }}</Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
    <Modal
      v-model="showCancelTicketModal"
      :title="$t('che-xiao-gong-dan-que-ren')"
    >
      <p>{{ $t('gong-dan-che-xiao-hou-bu-ke-hui-fu-que-ren-yao-che-xiao-gai-gong-dan-ma') }}</p>
      <div slot="footer">
        <Button type="primary" @click="cancelTicket">{{ $t('que-ding') }}</Button>
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
      </div>
    </Modal>
    <Modal v-model="showRollbackSqlModal"
             :title="$t('cha-kan-hui-gun-sql')"
             :width="1000">
      <read-only-editor :text="ticketDetail.rollBackSql" key="rollback" :max-height="500"/>
      <div slot="footer">
        <Button type="primary" @click="copyText(ticketDetail.rollBackSql)">{{
            $t('fu-zhi-sql')
          }}
        </Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </div>
    </Modal>
    <Modal :title="$t('zhi-hang')" v-model="showManualExecuteModal" width="800">
      <Alert type="warning" show-icon>
        {{ $t('xia-mian-sql-xi-tong-bu-hui-zhi-hang-xu-yao-ren-gong-fang-shi-zai-shu-ju-ku-zhong-shou-dong-zhi-hang-dang-nin-du-chu-li-hao-hou-ke-yi-dian-ji-xia-mian-que-ren-bing-guan-bi-lai-jie-shu-gong-dan') }}
      </Alert>
      <read-only-editor :text="ticketDetail.rawSql" :max-height="300"/>
      <Form>
        <FormItem :label="$t('bei-zhu')">
          <Input v-model="confirmInfo.comment" type="textarea"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="primary" @click="handleConfirmTicket">{{ $t('que-ren-bing-guan-bi') }}</Button>
        <Button @click="copyText(ticketDetail.rawSql)">{{ $t('fu-zhi-sql') }}</Button>
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
      </div>
    </Modal>
    <Modal :title="$t('ti-shi')" v-model="showCloseTicketModal" @on-ok="closeTicket" @on-cancel="handleCloseModal">
      {{ $t('que-ding-yao-guan-bi-gong-dan-ma') }}
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  TICKET_STATUS,
  TICKET_STATUS_COLOR
} from '@/const';
import ReadOnlyEditor from '@/components/editor/ReadOnlyEditor';
import copyMixin from '@/mixins/copyMixin';
import { Modal } from 'view-design';

export default {
  name: 'TicketDetail',
  components: {
    ReadOnlyEditor
  },
  mixins: [copyMixin],
  data() {
    return {
      showCloseTicketModal: false,
      activeSqlTab: 'raw',
      showManualExecuteModal: false,
      showRollbackSqlModal: false,
      showApprovalModal: false,
      approvalData: {
        rejected: 'false',
        comment: ''
      },
      taskList: [],
      startId: 0,
      pageSize: 10,
      exportJobList: [],
      preStartIds: [],
      ticketId: 0,
      ticketDetail: {},
      TICKET_STATUS,
      TICKET_STATUS_COLOR,
      loading: false,
      confirmInfo: {
      },
      showCancelTicketModal: false,
      currentStep: 0
    };
  },
  mounted() {
    this.ticketId = this.$route.params.id;
    this.getTicketDetail('init');
  },
  computed: {
    ...mapState(['userInfo', 'myAuth'])
  },
  methods: {
    handleShowManualExecuteModal(type) {
      this.confirmInfo = {
        ticketId: this.ticketId,
        confirmActionType: type,
        confirmUid: this.userInfo.uid,
        conformerUid: this.userInfo.uid,
        comment: '',
        ddlSqlExecType: 'DIRECT',
        noneDdlSqlExecType: 'DIRECT'
      };
      this.showManualExecuteModal = true;
    },
    handleShowRollbackSqlModal() {
      this.showRollbackSqlModal = true;
    },
    handleShowCloseTicketModal() {
      this.showCloseTicketModal = true;
    },
    async getTicketDetail(type) {
      this.loading = true;
      const data = {
        ticketId: this.ticketId
      };
      this.currentStep = 0;
      const res = await this.$services.queryTicketDetail({ data });
      this.loading = false;
      if (res.success) {
        this.ticketDetail = res.data;
        this.ticketDetail.ticketProcessVOList.forEach((item, index) => {
          item.execUserName = '';
          item.execMsg = '';
          if (item.stageContext) {
            const stageContext = JSON.parse(item.stageContext);
            item.execUserName = stageContext.execUserName ? stageContext.execUserName.join(',') : '';
            item.execUserNameList = stageContext.execUserName;
            item.execMsg = stageContext.execMsg;
          }
          if (item.finishTime) {
            this.currentStep++;
          }

          if (item.ticketProcessStatus === 'FINISH') {
            item.label = this.$t('yi-wan-cheng');
            item.labelColor = '#52C41A';
            item.icon = 'ios-checkmark-circle';
            item.color = '#52C41A';
          } else if (item.ticketProcessStatus === 'INIT') {
            item.label = this.$t('wei-kai-shi');
            item.labelColor = '#ccc';
            item.icon = 'ios-time';
            item.color = '#ccc';
            if (this.currentStep === index) {
              item.icon = 'ios-loading';
              item.color = '#0087c7';
              item.label = this.$t('jin-hang-zhong');
              item.labelColor = '#0087c7';
            }
          } else if (item.ticketProcessStatus === 'CLOSED') {
            item.label = this.$t('yi-guan-bi');
            item.labelColor = 'red';
            item.icon = 'ios-close-circle';
            item.color = 'red';
          } else if (item.ticketProcessStatus === 'REJECT') {
            console.log('reject');
            this.currentStep = -1;
            item.label = this.$t('yi-ju-jue');
            item.labelColor = 'red';
            item.icon = 'ios-remove-circle';
            item.color = 'red';
          } else if (item.ticketProcessStatus === 'FAIL') {
            item.label = this.$t('yi-shi-bai');
            item.labelColor = 'red';
          }
        });
      }
    },
    async cancelTicket() {
      const data = {
        ticketId: this.ticketId,
        approvalType: this.ticketDetail.approType,
        approIdentity: this.ticketDetail.approIdentity
      };
      const res = await this.$services.cancelTicket({ data });
      if (res.success) {
        this.$Message.success(this.$t('che-xiao-cheng-gong'));
        this.showCancelTicketModal = false;
        await this.getTicketDetail();
      }
    },
    async handleConfirmTicket() {
      const res = await this.$services.confirmTicket({ data: this.confirmInfo });
      if (res.success) {
        this.$Message.success(this.$t('cao-zuo-cheng-gong'));
        this.handleCloseModal();
        await this.getTicketDetail();
      }
    },
    handleShowApprovalModal() {
      this.showApprovalModal = true;
    },
    async handleApproval() {
      const { rejected, comment } = this.approvalData;
      const res = await this.$services.approvalTicket({
        data: {
          ticketId: this.ticketId,
          comment,
          rejected: rejected === 'true'
        }
      });

      if (res.success) {
        this.$Message.success(this.$t('shen-pi-cheng-gong'));
        this.handleCloseModal();
        await this.getTicketDetail();
      }
    },
    handleCloseModal() {
      this.approvalData = {
        rejected: 'false',
        comment: ''
      };
      this.showApprovalModal = false;
      this.showCancelTicketModal = false;
      this.showRollbackSqlModal = false;
      this.showManualExecuteModal = false;
      this.showCloseTicketModal = false;
    },
    async closeTicket() {
      const data = {
        ticketId: this.ticketId
      };
      const res = await this.$services.closeTicket({ data });
      if (res.success) {
        this.$Message.success(this.$t('guan-bi-cheng-gong'));
        await this.getTicketDetail();
      }
    }
  }
};
</script>

<style lang="less" scoped>
.ticket-detail-container {
  padding: 20px;
  position: relative;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ticket-detail-status {
    margin-top: 16px;

    /deep/ .ant-card-body {
      padding: 12px !important;
    }
  }

  .ticket-title-p {
    line-height: 20px;
    margin-bottom: 12px;
  }

  .ivu-card-head p, .ivu-card-head-inner {
    overflow: visible;
  }

  .ticket-title {
    font-size: 14px;
    font-family: PingFangSC-Semibold;
    font-weight: 500;
  }

  .ticket-status-total {
    display: inline-block;
    border: 1px solid #F8D090;
    background: #FFF8EC;
    border-radius: 10px;
    color: #FFA30E;
    font-size: 12px;
    padding: 2px 8px;
    margin-left: 8px;
    margin-right: 5px;
  }

  .ticket-detail-summary {
    font-size: 12px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    padding-right: 200px;

    .ticket-detail-item {
      margin-right: 80px;
      color: @font-color;
      display: inline-block;

      .ticket-detail-item-title {
        color: @icon-color;
      }
    }
  }

  .ticket-detail-operators {
    position: absolute;
    right: 14px;
    top: 10px;
    display: flex;

    button {
      margin-left: 10px;
    }
  }

  .ticket-content {
    margin-top: 20px;
  }

  .ticket-detail-wrapper {
    position: relative;
    color: @font-color;

    .step-item {
      padding: 7px;
      display: flex;
      align-items: center;
      width: 100%;

      &.current-step {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        border-radius: 5px;
        cursor: pointer;
      }

      .step-item-item {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1;

        .step-detail-label {
          min-width: 40px;
        }

        .line {
          height: 20px;
          width: 2px;
          background: red;
          position: absolute;
          left: 9px;
          bottom: 22px;
        }

        .status {
          display: flex;
          align-items: center;
          border-radius: 12px;
          padding-right: 4px;
          font-weight: bold;
          margin-right: 5px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .ticket-status {
    display: flex;
    align-items: center;
    margin-left: 5px;

    .content {
      padding: 2px 5px;
      border-radius: 2px;
      color: #fff;
      font-weight: bold;
    }
  }
}

.ellipsis {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  margin-top: 5px;
}

</style>
