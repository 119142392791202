<template>
  <div class="ticket-create-container">
    <Breadcrumb>
      <BreadcrumbItem to="/ticket">{{ $t('gong-dan') }}</BreadcrumbItem>
      <BreadcrumbItem>{{ $t('ti-jiao-gong-dan') }}</BreadcrumbItem>
    </Breadcrumb>
    <div class="create-content-container">
      <div class="create-ticket-editor">
        <div class="create-ticket-editor-operator">
          <DsSelect :ticketData="ticketData" :handleChangeInstance="handleChangeInstance"
                    :allDsList="allDsList" :handle-catalog-change="handleCatalogChange"
                    :selectedDs="selectedDs"></DsSelect>
        </div>
        <div class="editor">
          <div class="collapse raw">
            <div class="title"><b style="color: #f5222d">*</b>{{ $t('zhi-hang-sql') }}</div>
            <div class="content">
              <ticket-editor ref="rawSqlEditor"/>
            </div>
          </div>
          <div class="collapse rollback">
            <div class="title">{{ $t('hui-gun-sql') }}</div>
            <div class="content">
              <ticket-editor ref="rollbackSqlEditor"/>
            </div>
          </div>
        </div>
      </div>
      <div class="create-ticket-content">
        <a-form-model label-position="top" :model="ticketData" :rules="ticketRuleValidate" ref="ticketContent">
          <a-form-model-item :label="$t('biao-ti')" prop="ticketTitle">
            <a-input v-model="ticketData.ticketTitle"/>
          </a-form-model-item>
          <a-form-model-item :label="$t('xu-qiu-miao-shu')" prop="description">
            <a-input type="textarea" v-model="ticketData.description" :rows="4"/>
          </a-form-model-item>
          <a-form-model-item :label="$t('yu-gu-shou-ying-xiang-hang-shu')">
            <a-input v-model="ticketData.expectedAffectedRows" type="number"/>
          </a-form-model-item>
        </a-form-model>
        <div class="create-ticket-form-btn">
          <Button type="primary" :loading="loading" @click="handleSubmitTicket(false)">{{ $t('ti-jiao') }}</Button>
        </div>
      </div>
    </div>
    <Modal v-model="showNoPassedRuleModal" :title="$t('gui-ze-xiao-yan-shi-bai')" width="800">
      <Table :columns="noPassedRuleColumns" :data="noPassedRuleList" size="small" border>
        <template slot-scope="{ row }" slot="warnLevel">
          <Tag :color="row.ruleLevel === 'SUGGEST' ? 'warning' : 'error'" >
            {{RULE_WARN_LEVEL[row.ruleLevel]}}
          </Tag>
        </template>
      </Table>
      <template slot="footer">
        <Button type="primary" @click="handleSubmitTicket(true)" v-if="showForceBtn">{{ $t('ji-xu-ti-jiao') }}</Button>
        <Button @click="handleCloseModal">{{ $t('guan-bi') }}</Button>
      </template>
    </Modal>
  </div>
</template>
<script lang="js">
import DsSelect from '@/views/ticket/components/DsSelect';
import { APPROVAL_BIZ_TYPE } from '@/const';
import TicketEditor from '@/components/editor/TicketEditor';
import { hasSchema, RULE_WARN_LEVEL } from '@/utils';

export default {
  name: 'Ticket',
  components: {
    TicketEditor,
    DsSelect
  },
  data() {
    return {
      showForceBtn: false,
      RULE_WARN_LEVEL,
      noPassedRuleList: [],
      noPassedRuleColumns: [
        {
          title: this.$t('deng-ji'),
          slot: 'warnLevel',
          width: 90
        },
        {
          title: this.$t('ming-cheng'),
          key: 'name',
          width: 200
        },
        {
          title: this.$t('miao-shu'),
          key: 'desc'
        }
      ],
      showNoPassedRuleModal: false,
      showRawSql: true,
      showRollbackSql: true,
      checkedSql: '',
      loading: false,
      currentMethod: '',
      allDsList: [],
      templateList: [],
      personList: [],
      selectedDs: {},
      ticketData: {
        showCatalogSelect: false,
        showSchemaSelect: false,
        instanceId: undefined,
        catalog: '',
        schema: '',
        approvalType: 'SELFMAKE',
        immediately: 'immediately',
        dataSourceType: 'MySQL',
        envId: '',
        approPersonUids: [],
        ticketTitle: '',
        expectedAffectedRows: ''
      },
      ticketRuleValidate: {
        ticketTitle: [
          {
            required: true,
            message: this.$t('biao-ti-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ],
        description: [
          {
            required: true,
            message: this.$t('xu-qiu-miao-shu-bu-neng-wei-kong'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  mounted() {
    this.listAllDs();
    this.ticketData.ticketTitle = `${this.$t('gong-dan')}${new Date().getTime()}`;
  },
  methods: {
    handleCloseModal() {
      this.showForceBtn = false;
      this.showNoPassedRuleModal = false;
    },
    handleCatalogChange() {
      const selectCatalog = this.selectedDs.CATALOG_LIST.find((catalog) => catalog.objName === this.ticketData.catalog);
      this.listLevels(selectCatalog.levels);
    },
    handlePreRequest() {
      if (this.currentMethod === 'listFirstLevel') {
        this.listSchemaFirstLevel();
      }
    },
    async listAllDs() {
      const res = await this.$services.listDsInsLevels({
        data: {
          levels: []
        }
      });
      if (res.success) {
        this.allDsList = res.data.map((ds) => ({
          ...ds, levels: [ds.objAttr.dsEnvId, ds.objId]
        }));
      }
    },
    async listLevels(levels) {
      const res = await this.$services.listDbLevels({
        data: {
          levels
        }
      });

      if (res.success) {
        if (res.data && res.data.length) {
          if (res.data[0].objType === 'SCHEMA') {
            this.ticketData.showSchemaSelect = true;
            this.selectedDs[`${res.data[0].objType}_LIST`] = res.data.map((schema) => ({ ...schema, levels: [...levels, schema.objName] }));
          }

          if (res.data[0].objType === 'CATALOG') {
            this.ticketData.schema = '';
            this.ticketData.showCatalogSelect = true;
            this.selectedDs[`${res.data[0].objType}_LIST`] = res.data.map((catalog) => ({ ...catalog, levels: [...levels, catalog.objName] }));
          }
        }
      }
    },
    async handleSubmitTicket(force = false) {
      this.$refs.ticketContent.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const dbLevels = [this.ticketData.envId, this.ticketData.instanceId];

          if (this.ticketData.catalog && hasSchema(this.ticketData.dataSourceType)) {
            dbLevels.push(this.ticketData.catalog);
          }

          if (this.ticketData.schema) {
            dbLevels.push(this.ticketData.schema);
          }
          const data = {
            approvalType: this.ticketData.approvalType,
            dbLevels,
            rawSql: this.$refs.rawSqlEditor.getSql(),
            rollBackSql: this.$refs.rollbackSqlEditor.getSql(),
            description: this.ticketData.description,
            ticketTitle: this.ticketData.ticketTitle,
            expectedAffectedRows: this.ticketData.expectedAffectedRows,
            immediately: this.ticketData.immediately === 'immediately',
            templateIdentity: '',
            approTemplateName: '',
            force
          };
          const res = await this.$services.createTicket({ data });
          if (res.success) {
            if (res.data.failure || res.data.confirm) {
              if (res.data.confirm && !res.data.failure) {
                this.showForceBtn = true;
              }
              this.showNoPassedRuleModal = true;
              this.noPassedRuleList = res.data.checkedVOS;
            } else {
              await this.$router.push({ path: `/ticket/${res.data.ticketId}` });
            }
          }
          this.loading = false;
        } else {
          return false;
        }
      });
    },
    async listDsSchema() {
      const res = await this.$services.listDsSchema({
        data: {
          dataSourceId: this.ticketData.instanceId,
          parentData: this.ticketData.database,
          useVisibility: true
        }
      });
      if (res.success) {
        this.ticketData.schemaList = res.data;
        this.ticketData = { ...this.ticketData };
      }
    },
    async listSchemaFirstLevel() {
      const res = await this.$services.listSchemaFirstLevel({
        data: {
          dataSourceId: this.ticketData.instanceId,
          useVisibility: true
        },
        page: 'ticket'
      });
      if (res.success) {
        this.ticketData.dbList = res.data.nameList;
        this.ticketData.hasNextLevel = res.data.hasNextLevel;
        this.ticketData = { ...this.ticketData };
      }
      this.currentMethod = 'listFirstLevel';
    },
    handleChangeInstance(e) {
      this.ticketData.database = null;
      this.ticketData.schema = null;
      this.ticketData.showCatalogSelect = false;
      this.ticketData.showSchemaSelect = false;
      this.allDsList.forEach((ds) => {
        if (ds.objId === this.ticketData.instanceId) {
          this.selectedDs = ds;
          this.ticketData.dataSourceType = ds.objAttr.dsType;
          this.ticketData.envId = ds.objAttr.dsEnvId;
        }
      });

      this.listLevels(this.selectedDs.levels);
    }
  }
};
</script>
<style lang="less">
.ticket-create-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .create-content-container {
    margin-top: 10px;
    display: flex;
    height: 100%;

    .create-ticket-editor {
      flex: 1;
      display: flex;
      flex-direction: column;
      .create-ticket-editor-operator {
        padding: 13px 10px;
        border: 1px solid #EAEAEA;
        border-right: none;

        .ivu-select:first-child {
          margin-right: 4px;
        }
      }

      .editor {
        flex: 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        padding: 10px;
        border-left: 1px solid #EAEAEA;
        border-bottom: 1px solid #EAEAEA;

        .collapse {
          display: flex;
          flex-direction: column;

          &.raw {
            flex: 3
          }

          &.rollback {
            flex: 2;
          }

          &:first-child {
            .content {
              border-bottom: none;
              border-top: none;
            }
          }

          .title {
            padding-left: 10px;
            border: 1px solid #ccc;
            height: 36px;
            display: flex;
            align-items: center;
            font-weight: bold;
          }

          .content {
            flex: 1;
            border: 1px solid #ccc;
            border-top: none;
          }
        }
      }
    }

    .create-ticket-content {
      width: 300px;
      border: 1px solid #DADADA;
      background: @background-grey;
      padding: 20px;
      height: 100%;

      .ticket-template-container {
        .ticket-from {
          display: inline-block;
        }

        .ivu-select {
          display: inline-block;
        }
      }

      .create-ticket-form-btn {
        margin-top: 20px;

        button {
          margin-right: 16px;
        }
      }
    }
  }
}
</style>
