<template>
  <div>
    <a-select style="width: 240px;margin-right:8px" v-model="ticketData.instanceId" @change="handleChangeInstance"
              :placeholder="$t('qing-xuan-ze-shu-ju-yuan-shi-li')" show-search>
      <a-select-option v-for="ds in allDsList" :value="ds.objId" :key="ds.objId">
        <div style="display: flex;align-items: center">
          <cc-svg-icon :name="ds.objAttr.dsType" style="margin-right: 5px;"/>
          {{ ds.objName }}
        </div>
      </a-select-option>
    </a-select>
    <a-select style="width: 200px;margin-right:8px" v-model="ticketData.catalog" @change="handleCatalogChange"
              :placeholder="$t('qing-xuan-ze-shu-ju-ku')" show-search v-if="this.ticketData.showCataLogSelect">
      <a-select-option v-for="catalog in selectedDs.CATALOG_LIST" :value="catalog.objName" :key="catalog.objName">
        {{
          catalog.objName
        }}
      </a-select-option>
    </a-select>
    <a-select style="width: 200px;margin-right:8px" v-model="ticketData.schema" @change="handleSchemaChange"
              show-search v-if="this.ticketData.showSchemaSelect">
      <a-select-option v-for="schema in selectedDs.SCHEMA_LIST" :value="schema.objName" :key="schema.objName">
        {{schema.objName}}
      </a-select-option>
    </a-select>
  </div>
</template>
<script>
export default {
  props: {
    ds: Object,
    ticketData: Object,
    allDsList: Array,
    handleChangeInstance: Function,
    handleCatalogChange: Function,
    selectedDs: Object,
    handleSchemaChange: Function
  }
};
</script>
